import _get from "lodash/get"
import _map from "lodash/map"

export const adapterEssayVideos = (data) => {
  const essayVideos = _get(data, "videos", [])

  return _map(essayVideos, (video) => {
    const pathVideo = video.urlVideo
    const firstIndexSubstring = pathVideo.indexOf("-") + 1
    const lastIndexSubstring = pathVideo.length
    const videoName = pathVideo.substring(
      firstIndexSubstring,
      lastIndexSubstring
    )
    const url = `${process.env.S3_IMAGES}/${pathVideo}`

    return {
      ...video,
      uid: video.id,
      name: videoName,
      status: "done",
      path: pathVideo,
      url,
    }
  })
}
