import _get from "lodash/get"
import _map from "lodash/map"

export const adapterEssayPhotos = (data) => {
  const essayPhotos = _get(data, "photos", [])

  return _map(essayPhotos, (photo) => {
    const pathPhoto = photo.urlPhoto
    const firstIndexSubstring = pathPhoto.indexOf("-") + 1
    const lastIndexSubstring = pathPhoto.length
    const photoName = pathPhoto.substring(
      firstIndexSubstring,
      lastIndexSubstring
    )
    const url = `${process.env.S3_IMAGES}/${pathPhoto}`

    return {
      ...photo,
      uid: photo.id,
      name: photoName,
      status: "done",
      path: pathPhoto,
      url,
    }
  })
}
