import { EssayStatus, MediaType } from "@modules/Essay/store"
import _get from "lodash/get"
import _map from "lodash/map"

export const insertOrUpdateEssayAdapterPayload = ({
  values,
  photographerCheckbox,
  isEdit,
  removedPhotos,
  removedVideos,
  listOfVideoFilePaths,
  listOfPhotoFilePaths,
  filePathCover,
}) => {
  const essayCoverPath = _get(values, "essayCover.fileList.0.path", null)
  const essayPhotos = _get(values, "essayPhotos.fileList", [])
  const essayVideos = _get(values, "essayVideos.fileList", [])
  const name = _get(values, "essayName", null)
  const modelId = _get(values, "modelId", null)
  const category = _get(values, "category", null)
  const photographerId = !photographerCheckbox
    ? _get(values, "photographerId", null)
    : null

  const essayStatus = isEdit ? {} : { status: EssayStatus.ACTIVE }

  const photos = _map(essayPhotos, (photo, i) => {
    const status = EssayStatus.ACTIVE
    const type = MediaType.PHOTO

    const imagePhoto = {
      name: _get(photo, "name", null),
      data: _get(photo, "thumbUrl", null),
    }

    if (isEdit && !imagePhoto.data) {
      return {
        id: photo.id,
        urlPhoto: photo.path,
        status,
        type,
      }
    }

    return {
      urlPhotoVideo: listOfPhotoFilePaths[i],
      status,
      type,
    }
  })

  const videos = _map(essayVideos, (video, i) => {
    const status = EssayStatus.ACTIVE
    const type = MediaType.VIDEO

    const imagePhoto = {
      name: _get(video, "name", null),
      data: _get(video, "preview", null),
    }

    if (isEdit && !imagePhoto.data) {
      return {
        id: video.id,
        urlPhoto: video.path,
        status,
        type,
      }
    }

    return {
      urlPhotoVideo: listOfVideoFilePaths[i],
      status,
      type,
    }
  })

  const adaptedRemovedPhotos =
    isEdit && removedPhotos.length
      ? _map(removedPhotos, (removedPhoto) => {
          const status = EssayStatus.DELETED
          const type = MediaType.PHOTO

          const id =
            removedPhoto && removedPhoto.id ? { id: removedPhoto.id } : {}

          return {
            ...id,
            urlPhoto: removedPhoto.path,
            status,
            type,
          }
        })
      : []

  const adaptedRemovedVideos =
    isEdit && removedVideos.length
      ? _map(removedVideos, (removedVideo) => {
          const status = EssayStatus.DELETED
          const type = MediaType.VIDEO

          const id =
            removedVideo && removedVideo.id ? { id: removedVideo.id } : {}

          return {
            ...id,
            urlVideo: removedVideo.urlVideo,
            status,
            type,
          }
        })
      : []

  const essayPhotosVideos = [
    ...photos,
    ...videos,
    ...adaptedRemovedPhotos,
    ...adaptedRemovedVideos,
  ]

  return {
    name,
    modelId,
    category,
    essayPhotosVideos,
    photographerId,
    urlEssayCover: filePathCover || essayCoverPath,
    ...essayStatus,
  }
}
