import _get from "lodash/get"
import moment from "moment"

export const scheduleAdapterPayload = ({ values, essayId, action }) => {
  const postDate = _get(values, "postDate", null)

  const schedulePosting = moment(postDate, "DD/MM/YYYY HH:mm").isValid()
    ? { schedulePosting: moment(postDate, "DD/MM/YYYY HH:mm").format() }
    : {}

  return {
    essayId,
    action,
    ...schedulePosting,
  }
}
