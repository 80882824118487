import _get from "lodash/get"

export const adapterEssayCover = (data) => {
  const essayCover = _get(data, "urlEssayCover", null)

  if (!essayCover) return []

  const firstIndexSubstring = essayCover.indexOf("-") + 1
  const lastIndexSubstring = essayCover.length
  const essayCoverName = essayCover.substring(
    firstIndexSubstring,
    lastIndexSubstring
  )

  return [
    {
      uid: -1,
      name: essayCoverName,
      status: "done",
      path: essayCover,
      url: `${process.env.S3_IMAGES}/${essayCover}`,
    },
  ]
}
