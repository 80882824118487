import "./formEssay.less"

import { ButtonsForm } from "@components/ButtonsForm"
import { Icon } from "@components/Icons"
import { useStores } from "@hooks/use-stores"
import { adapterEssayCover } from "@modules/Essay/Admin/adapters/essayCover"
import { adapterEssayPhotos } from "@modules/Essay/Admin/adapters/essayPhotos"
import { scheduleAdapterPayload } from "@modules/Essay/Admin/adapters/essaySchedule"
import { adapterEssayVideos } from "@modules/Essay/Admin/adapters/essayVideos"
import { insertOrUpdateEssayAdapterPayload } from "@modules/Essay/Admin/adapters/registerOrEditEssay"
import { EssayActions } from "@modules/Essay/store"
import { errorsAdapter } from "@modules/Registration/SubscriberRegistration/adapters/registration"
import {
  Card,
  Checkbox,
  Col,
  Form,
  Input,
  Modal,
  Row,
  Select,
  Upload,
} from "antd"
import { navigate } from "gatsby"
import _filter from "lodash/filter"
import _get from "lodash/get"
import _map from "lodash/map"
import moment from "moment"
import React, { useEffect, useState } from "react"
import InputMask from "react-input-mask"
import { Player } from "video-react"

export const FormEssay = ({
  models,
  photographers,
  categorys,
  setErrors,
  loadingAffiliated,
  essayData,
  isEdit,
  essayId,
  contentWidth,
}) => {
  const [scheduleCheckbox, setScheduleCheckbox] = useState(false)
  const [photographerCheckbox, setPhotographerCheckbox] = useState(false)
  const [previewVideo, setPreviewVideo] = useState({
    visible: false,
    video: "",
    title: "",
    fileList: [],
  })
  const [preview, setPreview] = useState({
    visible: false,
    image: "",
    title: "",
    fileList: [],
  })
  const [previewCover, setPreviewCover] = useState({
    visible: false,
    image: "",
    title: "",
    fileList: {},
  })
  const [loading, setLoading] = useState(false)
  const [essayPhotos, setEssayPhotos] = useState(null)
  const [essayVideos, setEssayVideos] = useState(null)
  const [essayCover, setEssayCover] = useState(null)
  const [removedPhotos, setRemovedPhotos] = useState([])
  const [removedVideos, setRemovedVideos] = useState([])
  const [model, setModel] = useState(null)
  const [photographer, setPhotographer] = useState(null)
  const [videoFiles, setVideoFiles] = useState([])
  const [photoFiles, setPhotoFiles] = useState([])
  const [coverFile, setCoverFile] = useState(null)

  const [form] = Form.useForm()

  const { essay, upload } = useStores()

  const { insert, update, remove, publish } = essay

  const { getSignedUrlS3, uploadToS3 } = upload

  useEffect(() => {
    if (isEdit && essayData) {
      const essayName = _get(essayData, "name", "")
      const modelId = _get(essayData, "modelId", null)
      const photographerId = _get(essayData, "photographerId", null)
      const postDate = _get(essayData, "schedulePosting", null)
      const category = _get(essayData, "category", null)

      const adaptedEssayPhotos = adapterEssayPhotos(essayData)
      const adaptedEssayCover = adapterEssayCover(essayData)
      const adaptedEssayVideos = adapterEssayVideos(essayData)

      const essayPhotos = adaptedEssayPhotos.length
        ? { fileList: adaptedEssayPhotos }
        : null
      const essayVideos = adaptedEssayVideos.length
        ? { fileList: adaptedEssayVideos }
        : null
      const essayCover = adaptedEssayCover.length
        ? { fileList: adaptedEssayCover }
        : null

      setModel(modelId)
      setPhotographer(photographerId)
      setScheduleCheckbox(!postDate)
      setPhotographerCheckbox(!photographerId)
      setEssayPhotos(essayPhotos)
      setEssayVideos(essayVideos)
      setEssayCover(essayCover)

      form.setFieldsValue({
        essayName,
        modelId,
        photographerId,
        listedPhotographer: !photographerId,
        postDate: postDate ? moment(postDate).format("DD/MM/YYYY HH:mm") : null,
        schedulePost: !postDate,
        category,
        essayPhotos,
        essayVideos,
        essayCover,
      })
    }
  }, [isEdit, essayData])

  const uploadVideoFiles = async () => {
    const filePathList = []

    for await (let videoFile of videoFiles) {
      if (videoFile) {
        const paramsVideo = {
          mediaPath: "videos",
          fileName: videoFile.name,
          s3Path: "essay",
        }

        const signedUrlVideoRes = await getSignedUrlS3(paramsVideo)

        if (!signedUrlVideoRes) {
          setLoading(false)
          const adaptedErrors = errorsAdapter("Error get signed url video")
          setErrors(adaptedErrors)
          return
        }

        const { url, filePath } = signedUrlVideoRes

        const uploadToS3VideoRes = await uploadToS3(url, videoFile)

        if (!uploadToS3VideoRes) {
          setLoading(false)
          const adaptedErrors = errorsAdapter("Error video upload to S3")
          setErrors(adaptedErrors)
          return
        }

        filePathList.push(filePath)
      }
    }

    return filePathList
  }

  const uploadPhotoFiles = async () => {
    const filePathList = []

    for await (let photoFile of photoFiles) {
      if (photoFile) {
        const paramsPhoto = {
          mediaPath: "images",
          fileName: photoFile.name,
          s3Path: "essay",
        }

        const signedUrlPhotoRes = await getSignedUrlS3(paramsPhoto)

        if (!signedUrlPhotoRes) {
          setLoading(false)
          const adaptedErrors = errorsAdapter("Error get signed url photo")
          setErrors(adaptedErrors)
          return
        }

        const { url, filePath } = signedUrlPhotoRes

        const uploadToS3PhotoRes = await uploadToS3(url, photoFile)

        if (!uploadToS3PhotoRes) {
          setLoading(false)
          const adaptedErrors = errorsAdapter("Error photo upload to S3")
          setErrors(adaptedErrors)
          return
        }

        filePathList.push(filePath)
      }
    }

    return filePathList
  }

  const onFinish = async (values) => {
    setLoading(true)

    let listOfVideoFilePaths = []
    let listOfPhotoFilePaths = []
    let filePathCover = null

    if (videoFiles.length) {
      listOfVideoFilePaths = await uploadVideoFiles()
    }

    if (photoFiles.length) {
      listOfPhotoFilePaths = await uploadPhotoFiles()
    }

    if (coverFile) {
      const paramsCover = {
        mediaPath: "images",
        fileName: coverFile.name,
        s3Path: "essay",
      }

      const signedUrlCoverRes = await getSignedUrlS3(paramsCover)

      if (!signedUrlCoverRes) {
        setLoading(false)
        const adaptedErrors = errorsAdapter("Error get signed url cover")
        setErrors(adaptedErrors)
        return
      }

      const { url, filePath } = signedUrlCoverRes

      const uploadToS3CoverRes = await uploadToS3(url, coverFile)

      if (!uploadToS3CoverRes) {
        setLoading(false)
        const adaptedErrors = errorsAdapter("Error photo upload to S3")
        setErrors(adaptedErrors)
        return
      }

      filePathCover = filePath
    }

    const payload = insertOrUpdateEssayAdapterPayload({
      values,
      photographerCheckbox,
      isEdit,
      removedPhotos,
      removedVideos,
      listOfVideoFilePaths,
      listOfPhotoFilePaths,
      filePathCover,
    })

    const res = isEdit ? await update(payload, essayId) : await insert(payload)

    setLoading(false)

    const errorMessage = _get(res, "message", "")

    if (errorMessage) {
      const adaptedErrors = errorsAdapter(errorMessage)
      setErrors(adaptedErrors)
      return
    }

    if (!scheduleCheckbox) {
      const schedulePayload = scheduleAdapterPayload({
        values,
        essayId: essayId ? essayId : res.id,
        action: EssayActions.SCHEDULE,
      })

      setLoading(true)

      const resp = await publish(schedulePayload)

      setLoading(false)

      const errorMessage = _get(resp, "message", "")

      if (errorMessage) {
        const adaptedErrors = errorsAdapter(errorMessage)
        setErrors(adaptedErrors)
        return
      }
    }

    handleNavigateToList()
  }

  const handleRemoveEssay = async () => {
    const res = await remove(essayId)

    const errorMessage = _get(res, "message", "")

    if (errorMessage) {
      const adaptedErrors = errorsAdapter(errorMessage)
      setErrors(adaptedErrors)
      return
    }

    handleNavigateToList()
  }

  const getBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = () => resolve(reader.result)
      reader.onerror = (error) => reject(error)
    })
  }

  const handlePreviewPhoto = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj)
    }

    setPreview({
      image: file.url || file.preview,
      visible: true,
      title: file.name || file.url.substring(file.url.lastIndexOf("/") + 1),
    })
  }

  const handlePreviewVideo = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj)
    }

    setPreviewVideo({
      video: file.url || file.preview,
      visible: true,
      title: file.name || file.url.substring(file.url.lastIndexOf("/") + 1),
    })
  }

  const handleChangePhoto = ({ fileList }) => {
    const files = _map(fileList, (item) => item.originFileObj)

    setPhotoFiles(files)

    const essayPhotos = fileList.length ? { fileList } : null

    setEssayPhotos(essayPhotos)

    form.setFieldsValue({ essayPhotos })

    setPreview({ fileList })
  }

  const handleChangeVideo = async ({ file, fileList }) => {
    const files = _map(fileList, (item) => item.originFileObj)

    setVideoFiles(files)

    setLoading(true)

    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj)
    }

    setLoading(false)
  }

  const handleChangeCover = async ({ file, fileList }) => {
    setCoverFile(file.originFileObj)

    const essayCover = fileList.length ? { fileList } : null

    setEssayCover(essayCover)

    form.setFieldsValue({ essayCover })

    setPreviewCover({ fileList })
  }

  const handlePreviewCover = async (file) => {
    if (!file.url && !file.previewCover) {
      file.previewCover = await getBase64(file.originFileObj)
    }

    setPreviewCover({
      image: file.url || file.previewCover,
      visible: true,
      title: file.name || file.url.substring(file.url.lastIndexOf("/") + 1),
    })
  }

  const handleChangeModel = (value) => {
    setModel(value)
    form.setFieldsValue({ modelId: value })
  }

  const handleChangePhotographer = (value) => {
    setPhotographer(value)
    form.setFieldsValue({ photographerId: value })
  }

  const handleChangeScheduleCheckbox = () => {
    form.setFieldsValue({ postDate: null })
    setScheduleCheckbox(!scheduleCheckbox)
  }

  const handleChangePhotographerCheckbox = () => {
    form.setFieldsValue({ photographerId: null })
    setPhotographerCheckbox(!photographerCheckbox)
  }

  const handleRemovePhoto = (file) => {
    if (isEdit) {
      setRemovedPhotos((prev) => [...prev, file])
    }

    const formFieldValue = form.getFieldValue("essayPhotos")

    const photos = _filter(
      formFieldValue.fileList,
      (item) => file.uid !== item.uid
    )

    const essayPhotos = photos.length ? { fileList: photos } : null

    setEssayPhotos(essayPhotos)

    form.setFieldsValue({ essayPhotos })
  }

  const handleRemoveVideo = (file) => {
    if (isEdit) {
      setRemovedVideos((prev) => [...prev, file])
    }

    const formFieldValue = form.getFieldValue("essayVideos")

    const videos = _filter(
      formFieldValue.fileList,
      (item) => file.uid !== item.uid
    )

    const essayVideos = videos.length ? { fileList: videos } : null

    setEssayVideos(essayVideos)

    form.setFieldsValue({ essayVideos })
  }

  const handleRemoveCover = (file) => {
    const formFieldValue = form.getFieldValue("essayCover")

    const cover = _filter(
      formFieldValue.fileList,
      (item) => file.uid !== item.uid
    )

    const essayCover = cover.length ? { fileList: cover } : null

    setEssayCover(essayCover)

    form.setFieldsValue({ essayCover })
  }

  const handleNavigateToList = () => {
    navigate("/admin/ensaios")
  }

  const validatePostDate = () => ({
    validator(rule, value) {
      if (value) {
        const postDate = moment(value, "DD/MM/YYYY HH:mm")
        const isValid = postDate.isValid()
        const isBefore = postDate.isBefore()

        if (!isValid || value.length < 10) {
          return Promise.reject("Data inválida")
        }

        if (isBefore) {
          return Promise.reject(
            "Data de agendamento deve ser maior que a data atual"
          )
        }
      }

      return Promise.resolve()
    },
  })

  const VALIDATE_MESSAGES = {
    required: "Campo obrigatório",
  }

  if (!essayData && isEdit) {
    return "Carregando..."
  }

  return (
    <Form
      form={form}
      layout="vertical"
      onFinish={onFinish}
      scrollToFirstError
      className="sessionFormContext"
      initialValues={{
        essayName: null,
        modelId: null,
        photographerId: null,
        listedPhotographer: false,
        postDate: null,
        schedulePost: false,
        category: null,
        essayVideos: null,
        essayPhotos: null,
        essayCover: null,
      }}
      validateMessages={VALIDATE_MESSAGES}
    >
      <div className="mb24">
        <Card title="Informações gerais">
          <Row gutter={24}>
            <Col span={24} md={16} lg={12}>
              <Form.Item
                label="Nome do ensaio"
                name="essayName"
                rules={[{ required: true }]}
              >
                <Input placeholder="ex.: IN HOUSI" />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={24}>
            <Col span={24} md={12} lg={8}>
              <Form.Item
                label="Modelo"
                name="modelId"
                rules={[{ required: true }]}
                disabled={loadingAffiliated}
              >
                <Select
                  placeholder="Selecione uma modelo"
                  allowClear
                  value={model}
                  onChange={handleChangeModel}
                >
                  {_map(models, (model) => (
                    <Select.Option key={model.id} value={model.id}>
                      {model.name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={24}>
            <Col span={24} md={12} lg={8}>
              <Form.Item
                label="Fotógrafo"
                name="photographerId"
                shouldUpdate
                rules={[{ required: !photographerCheckbox }]}
                disabled={loadingAffiliated}
              >
                <Select
                  placeholder="Selecione um fotógrafo"
                  disabled={photographerCheckbox}
                  allowClear
                  value={photographer}
                  onChange={handleChangePhotographer}
                >
                  {_map(photographers, (photographer) => {
                    return (
                      <Select.Option
                        key={photographer.id}
                        value={photographer.id}
                      >
                        {photographer.name}
                      </Select.Option>
                    )
                  })}
                </Select>
              </Form.Item>
            </Col>

            <Col span={24} md={12} lg={8}>
              <Form.Item
                valuePropName="checked"
                value={photographerCheckbox}
                onChange={handleChangePhotographerCheckbox}
                label="&nbsp;"
                name="listedPhotographer"
              >
                <Checkbox>Fotógrafo não listado</Checkbox>
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={24}>
            <Col span={24} md={12} lg={8}>
              <Form.Item
                label="Programar postagem"
                name="postDate"
                shouldUpdate
                rules={[{ required: !scheduleCheckbox }, validatePostDate]}
              >
                {scheduleCheckbox ? (
                  <Input placeholder="ex.: 00/00/0000 00:00" disabled />
                ) : (
                  <InputMask
                    mask={"99/99/9999 99:99"}
                    maskChar={null}
                    placeholder="ex.: 00/00/0000 00:00"
                  >
                    {(inputProps) => <Input {...inputProps} />}
                  </InputMask>
                )}
              </Form.Item>
            </Col>

            <Col span={24} md={12} lg={16}>
              <Form.Item
                valuePropName="checked"
                value={scheduleCheckbox}
                onChange={handleChangeScheduleCheckbox}
                label="&nbsp;"
                name="schedulePost"
              >
                <Checkbox>Não programar postagem</Checkbox>
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={24}>
            <Col span={24} md={12} lg={8}>
              <Form.Item
                label="Categoria"
                name="category"
                rules={[{ required: true }]}
              >
                <Select placeholder="Selecione uma categoria" allowClear>
                  {_map(categorys, (category) => (
                    <Select.Option key={category.id} value={category.id}>
                      {category.name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>
        </Card>
      </div>

      <div className="mb24">
        <Card title="Vídeos">
          <p className="mb16 f16 fw6">Vídeos do ensaio</p>

          <Row>
            <Col span={24}>
              <div className="draggerContext">
                <Form.Item name="essayVideos">
                  <Upload.Dragger
                    multiple={true}
                    accept="video/*"
                    onPreview={handlePreviewVideo}
                    onChange={handleChangeVideo}
                    fileList={_get(essayVideos, "fileList", null)}
                    onRemove={handleRemoveVideo}
                    customRequest={({ onSuccess }) => {
                      setTimeout(() => {
                        onSuccess("ok")
                      }, 0)
                    }}
                  >
                    <p>
                      <Icon name="Inbox" className="f48 mb16" />
                    </p>

                    <p>Clique ou arraste vídeos para esta área.</p>
                  </Upload.Dragger>
                </Form.Item>
              </div>
            </Col>
          </Row>
        </Card>
      </div>

      <div className="mb24">
        <Card title="Fotos">
          <p className="mb16 f16 fw6">Fotos do ensaio</p>

          <Row>
            <Col span={24}>
              <div className="draggerContext">
                <Form.Item name="essayPhotos">
                  <Upload.Dragger
                    multiple={true}
                    onPreview={handlePreviewPhoto}
                    onChange={handleChangePhoto}
                    listType="picture-card"
                    accept="image/*"
                    fileList={_get(essayPhotos, "fileList", null)}
                    onRemove={handleRemovePhoto}
                    customRequest={({ onSuccess }) => {
                      setTimeout(() => {
                        onSuccess("ok")
                      }, 0)
                    }}
                  >
                    <p>
                      <Icon name="Inbox" className="f48 mb16" />
                    </p>

                    <p>Clique ou arraste as fotos para esta área.</p>
                  </Upload.Dragger>
                </Form.Item>
              </div>
            </Col>
          </Row>
        </Card>
      </div>

      <div>
        <Card title="Capa do ensaio" className="coverContext">
          <p className="mb16 f16 fw6">
            Adicione uma foto para capa do ensaio. A medida deve ser de 1200x907
          </p>

          <Row>
            <Col span={24}>
              <div className="draggerCover">
                <Form.Item name="essayCover" rules={[{ required: true }]}>
                  <Upload.Dragger
                    multiple={false}
                    onPreview={handlePreviewCover}
                    onChange={handleChangeCover}
                    listType="picture-card"
                    accept="image/*"
                    maxCount={1}
                    fileList={_get(essayCover, "fileList", null)}
                    onRemove={handleRemoveCover}
                    customRequest={({ onSuccess }) => {
                      setTimeout(() => {
                        onSuccess("ok")
                      }, 0)
                    }}
                  >
                    <p>
                      <Icon name="Inbox" className="f48 mb16" />
                    </p>

                    <p>Clique ou arraste a foto para esta área.</p>
                  </Upload.Dragger>
                </Form.Item>
              </div>
            </Col>
          </Row>
        </Card>
      </div>

      <ButtonsForm
        onCancel={handleNavigateToList}
        saveText={isEdit ? "Salvar" : "Cadastrar ensaio"}
        cancelText="Descartar"
        buttonLoading={loading}
        showRemoveButton={isEdit}
        removeButtonText="Remover ensaio"
        contentWidth={contentWidth}
        handleRemoveEssay={handleRemoveEssay}
        removeDialogText="Deseja realmente remover este ensaio?"
      />

      {previewCover.visible ? (
        <Modal
          centered
          visible={previewCover.visible}
          title={previewCover.title}
          onCancel={() => setPreviewCover({ visible: false })}
          footer={null}
        >
          <img
            alt="Pré-visualização"
            className="w-100"
            src={previewCover.image}
          />
        </Modal>
      ) : null}

      {preview.visible ? (
        <Modal
          centered
          visible={preview.visible}
          title={preview.title}
          onCancel={() => setPreview({ visible: false })}
          footer={null}
        >
          <img alt="Pré-visualização" className="w-100" src={preview.image} />
        </Modal>
      ) : null}

      {previewVideo.visible ? (
        <Modal
          centered
          visible={previewVideo.visible}
          title={previewVideo.title}
          onCancel={() => setPreviewVideo({ visible: false })}
          footer={null}
        >
          <Player playsInline src={previewVideo.video} />
        </Modal>
      ) : null}
    </Form>
  )
}
