import { CustomModalErrors } from "@components/CustomModalErrors"
import { LayoutAdmin } from "@components/LayoutAdmin"
import { useStores } from "@hooks/use-stores"
import { AffiliatedType } from "@modules/Affiliated/store"
import { FormEssay } from "@modules/Essay/Admin/components/FormEssay"
import { Category, EssayActions } from "@modules/Essay/store"
import { errorsAdapter } from "@modules/Registration/SubscriberRegistration/adapters/registration"
import { getParamsId } from "@utils/path"
import { navigate } from "gatsby"
import _filter from "lodash/filter"
import _get from "lodash/get"
import _sortBy from "lodash/sortBy"
import React, { useCallback, useEffect, useState } from "react"

const PageCreateSession = () => {
  const [errors, setErrors] = useState([])
  const [loadingAffiliated, setLoadingAffiliated] = useState([])
  const [models, setModels] = useState([])
  const [photographers, setPhotographers] = useState([])
  const [essayData, setEssayData] = useState(null)

  const { affiliated, essay } = useStores()

  const { findAll } = affiliated

  const { findById, publish } = essay

  const essayId = getParamsId()

  useEffect(() => {
    handleGetAllAffiliated()

    if (essayId) {
      handleGetEssayById()
    }
  }, [essayId])

  const handleGetAllAffiliated = useCallback(async () => {
    setLoadingAffiliated(true)

    const res = await findAll()

    setLoadingAffiliated(false)

    const data = _get(res, "data", [])

    const filterDataByTypeModel = _filter(data, (item) => {
      if (item.type === AffiliatedType.MODEL && item.name.trim()) {
        return {
          id: item.id,
          name: item.name,
        }
      }
    })

    const filterDataByTypePhotographer = _filter(data, (item) => {
      if (item.type === AffiliatedType.PHOTOGRAFER && item.name.trim()) {
        return {
          id: item.id,
          name: item.name,
        }
      }
    })

    setModels(_sortBy(filterDataByTypeModel, "name"))
    setPhotographers(_sortBy(filterDataByTypePhotographer, "name"))
  }, [])

  const handleGetEssayById = useCallback(async () => {
    const data = await findById(essayId)
    setEssayData(data)
  }, [])

  const handleDisableEssay = async () => {
    const res = await publish({
      essayId,
      action: EssayActions.DEACTIVATE,
    })

    const errorMessage = _get(res, "message", "")

    if (errorMessage) {
      const adaptedErrors = errorsAdapter(errorMessage)
      setErrors(adaptedErrors)
      return
    }

    navigate("/admin/ensaios")
  }

  const categorys = [
    {
      id: Category.NUDE,
      name: "Nude",
    },
    {
      id: Category.TOPLESS,
      name: "Topless",
    },
    {
      id: Category.LINGERIE,
      name: "Lingerie",
    },
  ]

  const essayName = _get(essayData, "name", "")

  const title = essayId ? essayName : "Cadastro de ensaio"
  const subTitle = essayId
    ? "Visualize e edite as infomações do ensaio."
    : "Insira as informações gerais e escolha fotos e vídeos para o ensaio."

  return (
    <LayoutAdmin
      title={title}
      subTitle={subTitle}
      data={essayData}
      isEdit={!!essayId}
      handleDisableEssay={handleDisableEssay}
    >
      <FormEssay
        isEdit={!!essayId}
        essayId={essayId}
        models={models}
        photographers={photographers}
        categorys={categorys}
        setErrors={setErrors}
        loadingAffiliated={loadingAffiliated}
        essayData={essayData}
      />

      {errors.length ? (
        <CustomModalErrors errors={errors} setErrors={setErrors} />
      ) : null}
    </LayoutAdmin>
  )
}

export default PageCreateSession
